<template>
    <div class="formulario">
        <v-container>
                <v-row>
                    <v-col>
                        <h3>
                            Sigue tu pedido
                        </h3>
                        <hr class="lineaRoja">
                        <span class="textoBajada">
                            Ingrese el número número de pedido para realizar el seguimiento de tu compra, tambien puede selectionar el numero de folio para utilizar 
                            el numero de la boleta o factura.
                        </span>
                        </br>
                        </br>
                    </v-col> 
                </v-row>
                <v-row>
                    <v-col lg="2">
                        <span>Tipo documento</span>
                        <v-select v-model="tipoDocumento" 
                            label="Selecciona el tipo de documento"
                            persistent-hint
                            return-object
                            single-line
                            :items="['Numero pedido', 'Folio Factura/Boleta']"
                            @change="actualizarTipoDocumento">
                        </v-select>                
                    </v-col>
                </v-row>
                <v-form @submit.prevent="validar()">
                    <v-row>
                        <v-col lg="6">
                            <v-text-field 
                                type="text" 
                                class="textoSeguimiento"
                                v-model="seguimiento"
                                v-bind:id="seguimiento"
                                ref="inputSeguimiento"
                                hide-details="auto" >
                            </v-text-field>
                            <div class = "red--text" v-if = "submited && !$v.seguimiento.required"> Este campo es obligatorio</div>
                        </v-col>
                        <v-col lg="3">
                            <v-btn
                                type="submit"
                                class="mx-3 white--text botonRojo" 
                                dark
                                ><!--v-on:click="buscarCourier"-->
                                Buscar
                                <v-icon>
                                    mdi-magnautocompleteify
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>      
        </v-container>
    </div>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import enPreparacion from './iconos/enPreparacion.vue'
export default {
    name: "Formulario",
    data() {
        return {
            submited: false,
            tipoDocumento:"Numero pedido",
            seguimiento: "",
            mostrarCargador: true
        };
    },
    mounted() {
        this.agregarAutocomplete();
    },
    methods: {
        validar(){

            this.submited = true;
            //this.$v.$touch();
            if(this.$v.$invalid){
                return false;
            }else { 
               this.buscarCourier();
            }
        },
        actualizarTipoDocumento(){
            this.$emit("enviarTipoDocumento", this.tipoDocumento);
        },
        buscarCourier() {
            this.$emit("ingresado", this.seguimiento);
        },
        agregarAutocomplete() {
            this.$refs.inputSeguimiento.autocomplete = "on";
            // document.getElementById("seguimiento").autocomplete="on"
        }
    },
    validations:{                           //Instanciamienta de validaciones segun variable con libreria VueValidators
        seguimiento:{required}
    }
}

/*
    Código simpliroute : 1228213444397-01
*/
</script>
