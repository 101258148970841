<template>
    <div class="footer">
            <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--footer-general items-stretch w-100">
    <div class="pr0 items-stretch vtex-flex-layout-0-x-stretchChildrenWidth flex" style="width: 100%;">
            <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-footer-general pb0" style="height: 100%;">
                <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--footer-general-fila-1">
                    <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--footer-general-fila-1 items-stretch w-100">
                        <div class="pr0 items-stretch flex" style="width: auto;">
                            <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer flex tc items-start justify-start t-body c-on-base">
                                        <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer"><p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer">CENTRO DE AYUDA</p></div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <nav class="vtex-menu-2-x-menuContainerNav vtex-menu-2-x-menuContainerNav--footer-col">
                                        <ul class="vtex-menu-2-x-menuContainer vtex-menu-2-x-menuContainer--footer-col list flex pl0 mv0 flex-column">
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        href="https://www.atika.cl/preguntas-frecuentes"
                                                        blockclass="footer-item"
                                                        id=""
                                                        title=""
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Preguntas frecuentes</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        href="https://www.atika.cl/cambios-y-garantias"
                                                        blockclass="footer-item"
                                                        id=""
                                                        src=""
                                                        link="[object Object]"
                                                        alt=""
                                                        title=""
                                                        analyticsproperties="none"
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Cambios y garantía</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        href="/condiciones-de-despacho"
                                                        blockclass="footer-item"
                                                        id=""
                                                        title=""
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Condiciones de despacho</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        href="/terminos-y-condiciones"
                                                        blockclass="footer-item"
                                                        id=""
                                                        title=""
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Términos y condiciones</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/promociones" blockclass="footer-item" id="" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Promociones y concursos</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/politica-de-privacidad" blockclass="footer-item" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Políticas de privacidad</div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div class="pr0 items-stretch flex" style="width: auto;">
                            <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer flex tc items-start justify-start t-body c-on-base">
                                        <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer"><p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer">MI CUENTA</p></div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <nav class="vtex-menu-2-x-menuContainerNav vtex-menu-2-x-menuContainerNav--footer-col">
                                        <ul class="vtex-menu-2-x-menuContainer vtex-menu-2-x-menuContainer--footer-col list flex pl0 mv0 flex-column">
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/login" blockclass="footer-item" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Iniciar Sesión</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/account#/orders" blockclass="footer-item" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Mis Pedidos</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        href="https://seguimiento.atika.cl/check-status"
                                                        blockclass="footer-item"
                                                        id=""
                                                        title=""
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Sigue tu pedido</div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div class="pr0 items-stretch flex" style="width: auto;">
                            <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer flex tc items-start justify-start t-body c-on-base">
                                        <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer"><p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer">ATIKA</p></div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                    <nav class="vtex-menu-2-x-menuContainerNav vtex-menu-2-x-menuContainerNav--footer-col">
                                        <ul class="vtex-menu-2-x-menuContainer vtex-menu-2-x-menuContainer--footer-col list flex pl0 mv0 flex-column">
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a
                                                        target="_blank"
                                                        href="https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=21519245"
                                                        blockclass="footer-item"
                                                        id=""
                                                        title=""
                                                        rel="noopener"
                                                        class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer"
                                                    >
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">PAGO EN LINEA</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/contacto" blockclass="footer-item" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Contacto</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/tiendas" blockclass="footer-item" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Tiendas</div>
                                                    </a>
                                                </div>
                                            </li>
                                            <li
                                                class="vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item list vtex-menu-2-x-menuItem vtex-menu-2-x-menuItem--footer-item vtex-menu-2-x-menuItem--isClosed vtex-menu-2-x-menuItem--footer-item--isClosed"
                                            >
                                                <div class="vtex-menu-2-x-styledLinkContainer vtex-menu-2-x-styledLinkContainer--footer-item mh6 pv2">
                                                    <a href="/nuestros-servicios" blockclass="footer-item" id="" title="" class="vtex-menu-2-x-styledLink vtex-menu-2-x-styledLink--footer-item no-underline pointer t-body c-on-base pointer">
                                                        <div class="vtex-menu-2-x-styledLinkContent vtex-menu-2-x-styledLinkContent--footer-item flex justify-between nowrap">Servicios</div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div class="pr0 items-stretch flex" style="width: auto;">
                            <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer-news ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer-news pb0" style="height: auto;">
                                    <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer flex tl items-start justify-start t-body c-on-base">
                                        <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer"><p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer">NEWSLETTER</p></div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer-news pb0" style="height: auto;">
                                    <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--texto-newsletter flex tl items-center justify-center t-body c-on-base">
                                        <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--texto-newsletter">
                                            <p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--texto-newsletter">Suscríbete a nuestro newsletter y recibe todas las novedades, ofertas, tendencias y más</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer-news pb0" style="height: auto;">
                                    <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-newsletter">
                                        <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--row-newsletter items-stretch w-100">
                                            <div class="pr0 items-stretch vtex-flex-layout-0-x-stretchChildrenWidth flex" style="width: 100%;">
                                                <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--form-newsletter ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                                    <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--form-newsletter pb0" style="height: 100%;">
                                                        <div class="vtex-store-components-3-x-newsletter vtex-store-components-3-x-newsletter--newsletter w-100">
                                                            <div class="vtex-store-components-3-x-container vtex-store-components-3-x-container--newsletter mw9 mr-auto ml-auto pv9">
                                                                <form class="vtex-store-components-3-x-form vtex-store-components-3-x-form--newsletter mw6 center tc ph5 ph0-ns">
                                                                    <label class="vtex-store-components-3-x-label vtex-store-components-3-x-label--newsletter t-heading-3 tc dn" for="newsletter-input">Suscríbete a nuestro newsletter</label>
                                                                    <div class="vtex-store-components-3-x-inputGroup vtex-store-components-3-x-inputGroup--newsletter flex-ns pt5">
                                                                        <label class="vtex-input w-100">
                                                                            <div class="vtex-input-prefix__group flex flex-row items-stretch overflow-hidden br2 bw1 b--solid b--muted-4 hover-b--muted-3 h-regular">
                                                                                <input
                                                                                    class="vtex-styleguide-9-x-input ma0 border-box vtex-styleguide-9-x-hideDecorators vtex-styleguide-9-x-noAppearance br2 w-100 bn outline-0 bg-base c-on-base b--muted-4 hover-b--muted-3 t-body ph5"
                                                                                    name="newsletter"
                                                                                    placeholder="Ingresa tu e-mail"
                                                                                    type="text"
                                                                                    id="newsletter-input"
                                                                                    value=""
                                                                                />
                                                                            </div>
                                                                        </label>
                                                                        <div class="vtex-store-components-3-x-buttonContainer vtex-store-components-3-x-buttonContainer--newsletter pl4-ns flex-none pt3 pt0-ns">
                                                                            <button
                                                                                tabindex="0"
                                                                                class="vtex-button bw1 ba fw5 v-mid relative pa0 lh-solid br2 min-h-regular t-action bg-action-primary b--action-primary c-on-action-primary hover-bg-action-primary hover-b--action-primary hover-c-on-action-primary pointer"
                                                                                type="submit"
                                                                            >
                                                                                <div class="vtex-button__label flex items-center justify-center h-100 ph6" style="padding-top: 0.25em; padding-bottom: 0.32em;">&gt;</div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer-news pb0" style="height: auto;">
                                    <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--footer-general-fila-2-col ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                        <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--footer-general-fila-2-col pb0" style="height: 100%;">
                                            <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer-medios flex tc items-start justify-start t-body c-on-base">
                                                <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer-medios">
                                                    <p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer-medios">MEDIOS DE PAGO</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--footer-general-fila-2-col pb0" style="height: 100%;">
                                            <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--texto-medios-pago-footer flex tc items-start justify-start t-body c-on-base">
                                                <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--texto-medios-pago-footer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pr0 items-stretch flex" style="width: auto;">
                            <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: 100%;">
                                    <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-footer-rrss-title">
                                        <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--row-footer-rrss-title items-stretch w-100">
                                            <div class="pr0 items-stretch flex" style="width: auto;">
                                                <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer-siguenos flex tc items-center justify-center t-body c-on-base">
                                                    <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer-siguenos">
                                                        <p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer-siguenos">SÍGUENOS</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: 100%;">
                                    <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-footer-rrss">
                                        <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--row-footer-rrss items-stretch w-100">
                                            <div class="pr0 items-stretch flex" style="width: auto;">
                                                <a href="https://www.facebook.com/AtikaConcept" title="" rel="" class="vtex-store-components-3-x-imageElementLink vtex-store-components-3-x-imageElementLink--footer-rrss-fb">
                                                    <img
                                                        title=""
                                                        sizes=""
                                                        srcset=""
                                                        alt=""
                                                        class="lazyload vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-rrss-fb vtex-render-runtime-8-x-lazyload"
                                                        crossorigin="anonymous"
                                                        data-src="https://atika.vtexassets.com/arquivos/2-faceb.png"
                                                        loading="lazy"
                                                        style="width: 45px;"
                                                    />
                                                </a>
                                            </div>
                                            <div class="pr0 items-stretch flex" style="width: auto;">
                                                <a href="https://twitter.com/atikaconcept" title="" rel="" class="vtex-store-components-3-x-imageElementLink vtex-store-components-3-x-imageElementLink--footer-rrss-wp">
                                                    <img
                                                        title=""
                                                        sizes=""
                                                        srcset=""
                                                        alt=""
                                                        class="lazyload vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-rrss-wp vtex-render-runtime-8-x-lazyload"
                                                        crossorigin="anonymous"
                                                        data-src="https://atika.vtexassets.com/arquivos/5-whats.png"
                                                        loading="lazy"
                                                        style="width: 45px;"
                                                    />
                                                </a>
                                            </div>
                                            <div class="pr0 items-stretch flex" style="width: auto;">
                                                <a href="https://www.pinterest.es/atikaconcept/" title="" rel="" class="vtex-store-components-3-x-imageElementLink vtex-store-components-3-x-imageElementLink--footer-rrss-pr">
                                                    <img
                                                        title=""
                                                        sizes=""
                                                        srcset=""
                                                        alt=""
                                                        class="lazyload vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-rrss-pr vtex-render-runtime-8-x-lazyload"
                                                        crossorigin="anonymous"
                                                        data-src="https://atika.vtexassets.com/arquivos/4-pinterest.png"
                                                        loading="lazy"
                                                        style="width: 45px;"
                                                    />
                                                </a>
                                            </div>
                                            <div class="pr0 items-stretch flex" style="width: auto;">
                                                <a href="https://www.instagram.com/atikaconcept/" title="" rel="" class="vtex-store-components-3-x-imageElementLink vtex-store-components-3-x-imageElementLink--footer-rrss-in">
                                                    <img
                                                        title=""
                                                        sizes=""
                                                        srcset=""
                                                        alt=""
                                                        class="lazyload vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-rrss-in vtex-render-runtime-8-x-lazyload"
                                                        crossorigin="anonymous"
                                                        data-src="https://atika.vtexassets.com/arquivos/1-insta.png"
                                                        loading="lazy"
                                                        style="width: 45px;"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: 100%;">
                                    <div class="vtex-flex-layout-0-x-flexCol vtex-flex-layout-0-x-flexCol--col-item-footer ml0 mr0 pl0 pr0 flex flex-column h-100 w-100">
                                        <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                            <div class="vtex-rich-text-0-x-container vtex-rich-text-0-x-container--titulo-footer-partner flex tc items-start justify-start t-body c-on-base">
                                                <div class="vtex-rich-text-0-x-wrapper vtex-rich-text-0-x-wrapper--titulo-footer-partner">
                                                    <p class="lh-copy vtex-rich-text-0-x-paragraph vtex-rich-text-0-x-paragraph--titulo-footer-partner">PARTNERS</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                            <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-footer-partners">
                                                <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--row-footer-partners items-stretch w-100">
                                                    <div class="pr0 items-stretch flex" style="width: auto;">
                                                        <img
                                                            title=""
                                                            sizes=""
                                                            srcset=""
                                                            alt=""
                                                            class="vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-partners vtex-render-runtime-8-x-lazyload ls-is-cached lazyloaded"
                                                            crossorigin="anonymous"
                                                            data-src="https://atika.vtexassets.com/assets/vtex/assets-builder/atika.store/2.4.46/icons/4-bchile___04ccf7e619d2f069cbd4ce9d38e64629.svg"
                                                            loading="lazy"
                                                            style="width: 130px;"
                                                            src="https://atika.vtexassets.com/assets/vtex/assets-builder/atika.store/2.4.46/icons/4-bchile___04ccf7e619d2f069cbd4ce9d38e64629.svg"
                                                        />
                                                    </div>
                                                    <div class="pr0 items-stretch flex" style="width: auto;">
                                                        <img
                                                            title=""
                                                            sizes=""
                                                            srcset=""
                                                            alt=""
                                                            class="vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-partners vtex-render-runtime-8-x-lazyload ls-is-cached lazyloaded"
                                                            crossorigin="anonymous"
                                                            data-src="https://atika.vtexassets.com/assets/vtex.file-manager-graphql/images/eb6f02e4-941f-4c8e-83aa-9f024b8f6d27___4d7ea83021b3565db1650bbd54aa4747.png"
                                                            loading="lazy"
                                                            style="width: 70px;"
                                                            src="https://atika.vtexassets.com/assets/vtex.file-manager-graphql/images/eb6f02e4-941f-4c8e-83aa-9f024b8f6d27___4d7ea83021b3565db1650bbd54aa4747.png"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vtex-flex-layout-0-x-flexColChild vtex-flex-layout-0-x-flexColChild--col-item-footer pb0" style="height: auto;">
                                            <div class="vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-footer-partners">
                                                <div class="flex mt0 mb0 pt0 pb0 justify-start vtex-flex-layout-0-x-flexRowContent vtex-flex-layout-0-x-flexRowContent--row-footer-partners items-stretch w-100">
                                                    <div class="pr0 items-stretch flex" style="width: auto;">
                                                        <img
                                                            title=""
                                                            sizes=""
                                                            srcset=""
                                                            alt=""
                                                            class="vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-partners vtex-render-runtime-8-x-lazyload lazyloaded"
                                                            crossorigin="anonymous"
                                                            data-src="https://atika.vtexassets.com/arquivos/aoa.png"
                                                            loading="lazy"
                                                            style="width: 92px;"
                                                            src="https://atika.vtexassets.com/arquivos/aoa.png"
                                                        />
                                                    </div>
                                                    <div class="pr0 items-stretch flex" style="width: auto;">
                                                        <img
                                                            title=""
                                                            sizes=""
                                                            srcset=""
                                                            alt=""
                                                            class="vtex-store-components-3-x-imageElement vtex-store-components-3-x-imageElement--footer-partners vtex-render-runtime-8-x-lazyload lazyloaded"
                                                            crossorigin="anonymous"
                                                            data-src="https://atika.vtexassets.com/arquivos/plataforma.png"
                                                            loading="lazy"
                                                            style="width: 104px;"
                                                            src="https://atika.vtexassets.com/arquivos/plataforma.png"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     
</div>

</template>

<style>
@import "https://atika.vtexassets.com/_v/public/assets/v1/bundle/css/asset.min.css?v=3&files=theme,9d1ec608ff3221ea0cf63a3e747cef8a.common,9d1ec608ff3221ea0cf63a3e747cef8a.small,9d1ec608ff3221ea0cf63a3e747cef8a.notsmall,9d1ec608ff3221ea0cf63a3e747cef8a.large,9d1ec608ff3221ea0cf63a3e747cef8a.xlarge&files=fonts,c53fd44abd83b4f215e09652ce42dba4b3ecf7f0&files=npm,animate.css@3.7.0/animate.min.css&files=react~vtex.render-runtime@8.132.4,common&files=react~vtex.store-components@3.159.5,Container,DiscountBadge,4,SearchBar,Logo&files=react~vtex.styleguide@9.146.1,0,1,Spinner,Tooltip&files=react~vtex.admin-pages@4.47.1,HighlightOverlay&files=react~vtex.flex-layout@0.19.0,0&files=react~vtex.rich-text@0.15.0,index&files=react~vtex.slider@0.8.3,0&files=react~vtex.shelf@1.47.2,0&files=react~vtex.wish-list@1.13.2,AddProductBtn&files=react~vtex.product-summary@2.80.0,ProductSummaryImage&files=react~vtex.search@2.13.0,Autocomplete&files=react~vtex.login@2.52.1,1&files=react~vtex.minicart@2.63.4,Minicart&files=overrides,vtex.product-list@0.35.0$overrides,vtex.minicart@2.63.4$overrides,atika.store@2.4.46$overrides&workspace=master";

</style>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>


