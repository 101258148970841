<template>
  <div data-app>
    <Formulario @ingresado="valorID" @enviarTipoDocumento="cambiarTipoDocumento"></Formulario>
     
    <v-dialog
      v-model="mostrarCargador"
      hide-overlay
      persistent
      width="300"
      >
      <v-card 
        color="#4E0108"
        dark
        class="d-flex align-center justify-center"
        min-height="40"
        >
          <v-card-text>
              <v-layout align-center justify-center>
                  <v-row
                  align="center">
                  <v-col>
                      <v-img
                        src="@/assets/cargando.gif"
                        height="50"
                        width="50"
                      ></v-img>
                    </v-col>
                    <v-col>
                        <v-container fluid fill-height>
                            <v-layout align-center justify-center>
                              <v-flex>
                                  <p>Por favor, espere...</p>
                              </v-flex>
                            </v-layout>
                        </v-container>
                          
                      </v-col>
                </v-row>
              </v-layout>
          </v-card-text>
      </v-card>
     
    </v-dialog>

    <v-dialog 
      v-model="mostrarError"
      width="500">
        <v-card
            color="#E9E9E9"
          >
            <v-card-title class="pt-5" color="#000000">
                No disponemos de información para ese pedido. Favor vuelve a ingresar la información.
            </v-card-title>
            <v-card-actions>
                <v-btn
                  dark
                  color="#4E0108" 
                  v-on:click="mostrarErrorClick">Cerrar ventana</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <InformacionPedido v-show="mostrarCapasResultado" :key="componenteInformacion" :encontrado="encontrado"></InformacionPedido>
  </div>
</template>

<script>
  import Formulario from '@/components/Formulario.vue'
  import InformacionPedido from '@/components/InformacionPedido.vue' 
  import moment from 'moment';
  import axios from 'axios'
  

  export default { 
    name: 'Home',
    components: {
    Formulario,
    InformacionPedido
},

    data() {
      return {
      mostrarCargador:false,
      mostrarCapasResultado: false,
      mostrarError:false,
      tipoDocumento:"Numero pedido",
      numOferta:"",
      numOrdenVenta:"",
      folio:'',
      ID:'',
      encontrado:'',
      encontradoApi:{
        ID: 0,
        nombre:'',
        direccion:'',
        status:'',
        camion:'',
        fecha_programada: ''
      },
      componenteInformacion:0,
      datos : ""
      }
    }, 

    created(){
      //this.buscarPentacrom();
    },
    methods: {
      valorID(ingresado) {   
        this.mostrarCapasResultado=false;
        this.mostrarCargador=true
        this.ID=ingresado
        this.validarTipoDocumento();
        //this.buscarShuck()
      },
      cambiarTipoDocumento(enviarTipoDocumento){
        this.tipoDocumento = enviarTipoDocumento;
        //console.log(this.tipoDocumento);
      },
      mostrarErrorClick() {
        this.mostrarError=false
      },

      resizeWindow() {
            setTimeout( function () {
                window.dispatchEvent(new Event('resize'))
            }, 250)
        },

      forcerender() {
        this.componenteInformacion +=1;
      },
      validarTipoDocumento(){

        if(this.tipoDocumento == "Numero pedido"){
          console.log("Numero de pedido");
          this.buscarDataPedido();
        }else{
          console.log("Numero de folio");
          this.buscarDataPedidoFolio();
        }
      },
      async buscarDataPedido(){

        this.folio = '';
        this.encontradoApi.ID = 0;
        this.encontradoApi.nombre = '';
        this.encontradoApi.direccion = '';
        this.encontradoApi.status = '';
        this.encontradoApi.camion = '';
        this.encontradoApi.fecha_programada = '';

        console.log("buscar datos del flujo");
        this.mostrarCargador=true;
        let id = this.ID
        //let ruta="http://localhost:8080/api-atika-landing/consultarFolio?noPedido=" + id;
        let ruta="https://api.atika.cl:23651/api-atika-landing/consultarFolio?noPedido=" + id;
        
        await axios.get(ruta).then(response => 
        {
          var datos = response.data;
          console.log(datos);
          console.log(datos.length);
        
          if(datos.length == 10){
            this.mostrarCargador=false;
            this.mostrarError=true;
          }else{

            this.numOferta = datos[0].numeroOferta;
            this.numOrdenVenta = datos[0].numeroOrdenVenta;
            let resFolio = datos[0].numeroFolio;
            //let rutaCliente = 'http://localhost:8080/api-atika-landing/consultar/cliente/oferta?noOferta=' + this.numOferta;
            let rutaCliente = 'https://api.atika.cl:23651/api-atika-landing/consultar/cliente/oferta?noOferta=' + this.numOferta;
            let dataCliente = "";

            if(resFolio != ""){
              let separador = "-"
              if(resFolio.includes(separador)){
                let foliosOriginales = resFolio.split('-');
                let ns = new Set(foliosOriginales);
                let folios = [...ns]
                folios.sort((a,b) => {
                  return b - a;
                })
                this.folio = folios[0];
                console.log(this.folio);
              }else{
                this.folio = resFolio;
                console.log(this.folio);
              }
              console.log("Buscar pedidos");
              this.buscarDatosClienteGuia();
            }else if(this.numOrdenVenta > 0){

              axios.get(rutaCliente).then(response => 
              { 
                dataCliente = response.data
                console.log(dataCliente);
                this.encontradoApi.ID = this.numOrdenVenta;
                this.encontradoApi.nombre = dataCliente[0].nombre;
                this.encontradoApi.direccion = dataCliente[0].direccion;
                this.encontradoApi.status = 'En preparacion';
                this.encontradoApi.camion = 'principio';
                this.encontrado = this.encontradoApi;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
                console.log("mostrar compra en preparacion");
                
              });

            }else{

              axios.get(rutaCliente).then(response => 
              { 
                dataCliente = response.data
                console.log(dataCliente);
                this.encontradoApi.ID = this.numOferta;
                this.encontradoApi.nombre = dataCliente[0].nombre;
                this.encontradoApi.direccion = dataCliente[0].direccion;
                this.encontradoApi.status = 'Compra confirmada';
                this.encontradoApi.camion = 'confirmada';
                this.encontrado = this.encontradoApi;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
                console.log("compra confirmada");
                
              });

            }

          }
          
        });
      },

      async buscarDataPedidoFolio(){

        this.folio = '';
        this.encontradoApi.ID = 0;
        this.encontradoApi.nombre = '';
        this.encontradoApi.direccion = '';
        this.encontradoApi.status = '';
        this.encontradoApi.camion = '';
        this.encontradoApi.fecha_programada = '';

        console.log("buscar datos del flujo Folio");
        this.mostrarCargador=true;
        let id = this.ID
        //let ruta="http://localhost:8080/api-atika-landing/consultarFolio/factura?noPedido=" + id;
        let ruta="https://api.atika.cl:23651/api-atika-landing/consultarFolio/factura?noPedido=" + id;

        await axios.get(ruta).then(response => 
        {
          var datos = response.data;
          console.log('Datos flujo folio');
          console.log(datos);

          if(datos.length == 10){
            this.mostrarCargador=false;
            this.mostrarError=true;
          }else{

            this.numOferta = datos[0].numeroOferta;
            this.numOrdenVenta = datos[0].numeroOrdenVenta;
            let resFolio = datos[0].numeroFolio;
            //let rutaCliente = 'http://localhost:8080/api-atika-landing/consultar/cliente/oferta?noOferta=' + this.numOferta;
            let rutaCliente = 'https://api.atika.cl:23651/api-atika-landing/consultar/cliente/oferta?noOferta=' + this.numOferta;
            let dataCliente = "";

            if(resFolio != ""){

              let separador = "-"
              if(resFolio.includes(separador)){
                let foliosOriginales = resFolio.split('-');
                let ns = new Set(foliosOriginales);
                let folios = [...ns]
                folios.sort((a,b) => {
                  return b - a;
                })
                this.folio = folios[0];
                console.log(this.folio);
              }else{
                this.folio = resFolio;
                console.log(this.folio);
              }
              console.log("Buscar pedidos");
              this.buscarDatosClienteGuia();

            }else if(this.numOrdenVenta > 0){

              axios.get(rutaCliente).then(response => 
              { 
                dataCliente = response.data
                console.log(dataCliente);
                this.encontradoApi.ID = this.numOrdenVenta;
                this.encontradoApi.nombre = dataCliente[0].nombre;
                this.encontradoApi.direccion = dataCliente[0].direccion;
                this.encontradoApi.status = 'En preparacion';
                this.encontradoApi.camion = 'principio';
                this.encontrado = this.encontradoApi;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
                console.log("mostrar compra en preparacion");
                
              });

            }else{

              axios.get(rutaCliente).then(response => 
              { 
                dataCliente = response.data
                console.log(dataCliente);
                this.encontradoApi.ID = this.numOferta;
                this.encontradoApi.nombre = dataCliente[0].nombre;
                this.encontradoApi.direccion = dataCliente[0].direccion;
                this.encontradoApi.status = 'Compra confirmada';
                this.encontradoApi.camion = 'confirmada';
                this.encontrado = this.encontradoApi;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
                console.log("compra confirmada");
                
              });

            }

          }
          
        });
      },
      buscarDatosClienteGuia(){
        let rutaCliente = 'https://api.atika.cl:23651/api-atika-landing/consultar/cliente?noGuia=' + this.folio;
        axios.get(rutaCliente).then(response => 
        { 
          let dataCliente = response.data
          this.buscarShuck(dataCliente)
          console.log(dataCliente);
        });
      },

      async buscarShuck(dataCliente) {
          
        console.log("buscar en shuck");
        this.encontradoApi.ID = 0;
        this.encontradoApi.nombre = '';
        this.encontradoApi.direccion = '';
        this.encontradoApi.status = '';
        this.encontradoApi.camion = '';
        this.encontradoApi.fecha_programada = '';

        //let id = this.ID;
        let folio = this.folio;
        let estado = '';
        //let ruta="https://api.atika.cl/tracking/schuck/prueba";  //-----Se debe quitar el /prueba
        let ruta="https://api.atika.cl/tracking/schuck";
        let parametro = {
          folio
          }
        //let params = {'id': this.ID}
        await axios.post(ruta, parametro).then(response => 
        {
            let datos = response.data;
            console.log(datos);

            if(datos == 'Despacho invalido'){
              this.buscarPentacrom(dataCliente);
            }else{
              
              //let cliente = datos.cliente;
              let datos_despacho = datos.datos_entrega;
              estado = datos_despacho[0].estado;

              if(estado == 'VIAJE 043648' || estado == 'REPARTO' || estado == 'BODEGA SANTIAGO' || estado == 'BODEGA OSORNO'){
              
              this.encontradoApi.status = 'Pedido en camino';
              this.encontradoApi.camion = 'intermedio';

              }else if (estado == 'DIGITALIZADA' || estado == 'RECEPCIONADA' || estado == 'ENTREGADA'){
                
                this.encontradoApi.status = 'Pedido Entregado';
                this.encontradoApi.camion = 'final';

              }else{
                this.encontradoApi.status = 'Compra Confirmada';
                this.encontradoApi.camion = 'confirmada';
              }
              this.encontradoApi.ID = datos_despacho[0].guia_schuck;
              this.encontradoApi.nombre = dataCliente[0].nombre;
              this.encontradoApi.direccion = dataCliente[0].direccion;
              this.encontradoApi.fecha_programada = datos_despacho[0].fecha + ' ' +  datos_despacho[0].hora;
              //console.log(this.encontradoApi);
              this.encontrado = this.encontradoApi;

              this.forcerender()
              this.resultadoVisible=true
              this.mostrarCargador=false
              this.mostrarCapasResultado=true
            }
          })
      },

      async buscarPentacrom(dataCliente){

        console.log("buscar en pentacrom");

        let folio = this.folio;
        //let ruta="https://api.atika.cl/tracking/pentacrom/prueba?folio=" + folio;
        let ruta="https://api.atika.cl/tracking/pentacrom?folio=" + folio;

        await axios.post(ruta).then(response => 
        {
          let datos = response.data;
          console.log(datos);

          if(datos == 'Despacho invalido'){ //Validar
            this.buscarSimpliRoute(dataCliente);
          }else{

            //let cliente = datos.cliente;
            let datos_despacho = datos.datos_entrega;
            let estado = datos_despacho[2];
            console.log(datos_despacho);

            if(estado == 1){
              this.encontradoApi.status = 'En preparacion';
              this.encontradoApi.camion = 'principio';
            }else if (estado == 2){
              this.encontradoApi.status = 'Pedido en camino';
              this.encontradoApi.camion = 'intermedio';
            }else if (estado == 3 ){
              this.encontradoApi.status = 'Pedido Entregado';
              this.encontradoApi.camion = 'final';
            }

            this.encontradoApi.ID = datos_despacho[1];
            this.encontradoApi.nombre = dataCliente[0].nombre;
            this.encontradoApi.direccion = dataCliente[0].direccion;
            this.encontradoApi.fecha_programada = datos_despacho[3];
            //console.log(this.encontradoApi);
            this.encontrado = this.encontradoApi;

            this.forcerender()
            this.resultadoVisible=true
            this.mostrarCargador=false
            this.mostrarCapasResultado=true

          }
          
          
        });
      },
      async buscarSimpliRoute(dataCliente) {
        console.log("buscar en simpliRoute");

        let folio = this.folio;
        //let ruta="https://api.atika.cl/tracking/simpliRoute/prueba"
        let ruta="https://api.atika.cl/tracking/simpliRoute"
        let parametro = {
          folio
          }
        await axios.post(ruta, parametro).then(response => 
        {
          let datos = response.data;
          console.log(datos);

          if(datos == 'Despacho invalido'){
            this.buscarAppControlGuias(dataCliente);
          }else{
            this.encontradoApi.ID = datos.id;
            this.encontradoApi.nombre = datos.nombre;
            this.encontradoApi.direccion = datos.direccion;
            this.encontradoApi.status = datos.estado;
            this.encontradoApi.camion = datos.camion;
            this.encontradoApi.fecha_programada = this.formateo_fechas(datos.fecha_programada);

            this.encontrado = this.encontradoApi;
            this.forcerender();
            this.resultadoVisible=true;
            this.mostrarCargador=false;
            this.mostrarCapasResultado=true;
          }

        });
      },
      async buscarCourier(dataCliente) {
         
        console.log("buscar en simpliRoute");
         //let ruta ="http://192.168.31.83/consulta_apis.php"
          //let ruta="https://tracking.atika.cl/php/consulta_apis_test.php"
          let ruta="https://tracking.atika.cl/php/consulta_apis.php"
          let params = {'id': this.ID}
          await  axios.get(ruta, {params}).then(response => 
          {
            //this.mostrarCargador=false
            this.encontrado=response.data
            this.respuesta=response.data
            console.log(this.respuesta);
            
            if (this.respuesta.encontrado==false || this.respuesta.encontrado==true)
            {
              this.mostrarCargador=false;
              //this.mostrarError=true
              //this.buscarPentacrom();
              this.buscarAppControlGuias(dataCliente);
            } else 
            {
              this.forcerender()
              this.resultadoVisible=true
              this.mostrarCargador=false
              this.mostrarCapasResultado=true
            }
                  
          })
        },

       
        async buscarAppControlGuias(dataCliente) {
          console.log("buscar en APP Control");

          //let id = this.ID;
          let folio = this.folio;
          let estado = '';
          //let ruta="https://api.atika.cl/tracking/appControl/prueba";
          let ruta="https://api.atika.cl/tracking/appControl";
          let parametro = {
            folio
          }

          //console.log(ruta);

          await  axios.post(ruta, parametro).then(response => 
          {
            let datos = response.data;
            console.log(datos);

            if(datos == 'guia no despachada' || datos == 'Despacho invalido'){
              
              this.encontradoApi.ID = this.numOrdenVenta;
              this.encontradoApi.nombre = dataCliente[0].nombre;
              this.encontradoApi.direccion = dataCliente[0].direccion;
              this.encontradoApi.status = 'En preparacion';
              this.encontradoApi.camion = 'principio';
              this.encontrado = this.encontradoApi;

              this.forcerender()
              this.resultadoVisible=true
              this.mostrarCargador=false
              this.mostrarCapasResultado=true
              console.log("mostrar compra en preparacion");

              //this.mostrarCargador=false;
              //this.mostrarError=true;
              //this.buscarCourier();
            }else{
              
              this.encontradoApi.ID = datos.id;
              this.encontradoApi.nombre = dataCliente[0].nombre;
              this.encontradoApi.direccion = dataCliente[0].direccion;
              this.encontradoApi.status = datos.status;
              this.encontradoApi.camion = datos.camion;
              this.encontradoApi.fecha_programada = datos.fecha_programada;

              this.encontrado = this.encontradoApi;

              this.forcerender()
              this.resultadoVisible=true
              this.mostrarCargador=false
              this.mostrarCapasResultado=true
            }
          });
        },
        formateo_fechas:function(fecha){

          fecha = fecha.toString().substr(0, 10); 
          fecha = moment(String(fecha)).format('DD/MM/YYYY');
          return fecha; 
      
        },
    }
  }

  /*

case 1: $resultado->status="Compra confirmada";
                        $resultado->camion="confirmada";       
                        break;
                case 2: $resultado->status="En preparación";
                        $resultado->camion="principio";
                        break;
                case 3: $resultado->status="Pendiente";
                        $resultado->camion="intermedio";
                        break;
                case 4: $resultado->status="Entregada";
                        $resultado->camion="final";

*/

</script>

